<template>
  <app-layout class="bg-background">
    <div class="container mx-auto">
      <app-loading-page :text="$t('general.loading')" :loading="submitLoading"
        class="w-40 h-20 m-auto rounded-md shadow-md bg-transparent" />
      <app-modal-user-detail :show="showModalDetail" @close="showModalDetail = false" :selectUser="selectUser"
        @update:selectUser="selectUser = $event" @refresh="refresh" />

      <!-- Download CSV Modal -->
      <app-modal :show="show_modal_download_csv" @close="show_modal_download_csv = false">
        <template v-slot:title>
          {{ $t("sales.download_csv_title") }}
        </template>
        <template v-slot:body>
          <p class="text-sm text-gray-500">
            {{ $t("sales.download_csv_description") }}
          </p>
        </template>
        <template v-slot:action>
          <app-button @click="downloadCsvListUser" :showf70Icon="false" type="button" class="sm:ml-3 sm:w-auto">
            {{ $t("general.download_csv") }}
          </app-button>
        </template>
      </app-modal>

      <app-table :apiResponse="apiPaginationResponse.data" :loading="apiPaginationLoading"
        @params-changed="params_changed" :showEmptyButton="false" :filters="[
          {
            key: 'filter[name]',
            type: 'text',
            placeholder: $t('user.detail.name'),
            value: '',
          },
          {
            key: 'filter[email]',
            type: 'text',
            placeholder: $t('user.detail.email'),
            value: '',
          },
          {
            key: 'filter[phone_no]',
            type: 'text',
            placeholder: $t('user.detail.phone_no'),
            value: '',
          },
          {
            key: 'filter[country_id]',
            type: 'select',
            placeholder: $t('user.detail.country'),
            items: countries,
          },
          {
            key: 'filter[has_businesses]',
            type: 'select',
            placeholder: $t('user.detail.has_business'),
            value: {},
            items: [
              {
                id: 'true',
                name: 'Yes',
              },
              {
                id: 'false',
                name: 'No',
              }              
            ],
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            value: '',
          },
        ]" @on-item-click="onItemClick">
        <template v-slot:button>
          <app-button @click="show_modal_download_csv = true" :showf70Icon="false" type="button"
            class="sm:ml-3 sm:w-auto">
            {{ $t("payout.modal.download_csv_button") }}
          </app-button>
        </template>
        <template v-slot:header>
          <th class="font-bold">{{ $t("user.detail.name") }}</th>
          <th class="font-bold">{{ $t("user.detail.email") }}</th>
          <th class="font-bold">{{ $t("user.detail.phone_no") }}</th>
          <th class="font-bold">{{ $t("user.detail.country") }}</th>
          <th class="font-bold">{{ $t("user.detail.register_at") }}</th>
        </template>
        <template v-slot:body="slotData">
          <td>
            {{ slotData.model.name }}
          </td>
          <td>
            {{ slotData.model.email }}
          </td>
          <td>
            {{ slotData.model.phone_no }}
          </td>
          <td>
            {{ slotData.model.country.name }}
          </td>
          <td>
            {{
            $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a")
            }}
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
      current_tab: null,
      showModalDetail: false,
      queryParams: "",
      selectUser: null,
      submitLoading: false,
      show_modal_download_csv: false,
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["userStore/apiPaginationResponse"];
    },
    countries() {
      return this.$store.getters["referenceStore/countries"];
    },
    apiPaginationLoading() {
      return this.$store.getters["userStore/apiPaginationLoading"];
    },
    loadingAuth() {
      return this.$store.getters["authStore/loading"];
    },
  },
  mounted() {
    this.fetchListUser();
    this.$store.dispatch("referenceStore/getCountries", 0);
  },
  methods: {
    refresh() {
      this.fetchListUser();
    },
    fetchListUser() {
      this.$store.dispatch("userStore/retrieveUsers", this.queryParams);
    },
    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectUser = data;
    },
    params_changed(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListUser();
    },
    async downloadCsvListUser() {
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "userStore/getSalesListUserExports",
        this.queryParams
      );

      this.submitLoading = false;

      if (!this.$lodash.isEmpty(csv_url.data)) {
        window.location.href = csv_url.data;
        this.fetchListUser();
      }
    },
  },
};
</script>